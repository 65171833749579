import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../colors";
import eventBus, { EVENT } from "../services/eventBus";

const LoadingIndicatorS = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
`;

const LinearActivity = styled.div`
  overflow: hidden;
  width: 100%;
  height: 0.2rem;
  background-color: ${colors.primaryLight};
  margin: auto;
`;

const Indeterminante = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  :before {
    content: "";
    position: absolute;
    height: 100%;
    background-color: ${colors.background};
    animation: indeterminate_first 1.5s infinite ease-out;
  }

  :after {
    content: "";
    position: absolute;
    height: 100%;
    background-color: ${colors.background};
    animation: indeterminate_second 1.5s infinite ease-in;
  }

  @keyframes indeterminate_first {
    0% {
      left: -100%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }

  @keyframes indeterminate_second {
    0% {
      left: -150%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }
`;

export const TopLoadingIndicator = () => {
  const [visible, setVisible] = useState(false);
  const loading = useRef(0);

  const setLoadingIndicatorVisible = () => {
    setVisible(true);
  };

  const setLoadingIndicatorInvisible = () => {
    setVisible(false);
  };

  const loadingStarted = () => {
    loading.current = loading.current + 1;
    setLoadingIndicatorVisible();
  };

  const loadingFinished = () => {
    loading.current = loading.current - 1;
    if (loading.current === 0) {
      setLoadingIndicatorInvisible();
    }
  };

  useEffect(() => {
    loading.current = 0;
    eventBus.register(EVENT.LOADING_STARTED, loadingStarted);
    eventBus.register(EVENT.LOADING_FINISHED, loadingFinished);
    return () => {
      eventBus.unregister(EVENT.LOADING_STARTED, loadingStarted);
      eventBus.unregister(EVENT.LOADING_FINISHED, loadingFinished);
      loading.current = 0;
    };
  }, []);

  return (
    visible && (
      <LoadingIndicatorS id="top-loading-indicator">
        <LinearActivity>
          <Indeterminante></Indeterminante>
        </LinearActivity>
      </LoadingIndicatorS>
    )
  );
};
