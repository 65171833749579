import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

const eventBus = {
  register: (event, functionToCall) => eventEmitter.on(event.name, functionToCall),

  unregister: (event, functionToCall) => eventEmitter.removeListener(event.name, functionToCall),

  emit: (event, payload) => eventEmitter.emit(event.name, payload)
};

export default eventBus;

export const EVENT = Object.freeze({
  UPDATE_FILTERS: { name: "UPDATE_FILTERS" },
  SELECT_BOX_SHOW_LIST: { name: "SELECT_BOX_SHOW_LIST" },
  SELECTED_SHELF: { name: "SELECTED_SHELF" },
  ROUTER_TOGGLE_RESPONSIVE_MENU: { name: "ROUTER_TOGGLE_RESPONSIVE_MENU" },
  LOADING_STARTED: { name: "LOADING_STARTED" },
  LOADING_FINISHED: { name: "LOADING_FINISHED" },
  TENANT_UPDATED: { name: "TENANT_UPDATED" },
  MODAL_HAS_UNSAVED_CHANGES: { name: "MODAL_NOT_CLOSABLE" }
});

// following section is for websocket

const createWebsocketEventType = service => {
  return "WEBSOCKET_EVENT_" + service.toUpperCase();
};
const WS_SERVICE_ADD = "WS_SERVICE_ADD";
const WS_SERVICE_REMOVE = "WS_SERVICE_REMOVE";
export const websocketBus = {
  registerService: (service, functionToCall) => {
    eventEmitter.on(createWebsocketEventType(service.name), functionToCall);
    eventEmitter.emit(WS_SERVICE_ADD, service);
  },

  unregisterService: (service, functionToCall) => {
    eventEmitter.removeListener(createWebsocketEventType(service.name), functionToCall);
    eventEmitter.emit(WS_SERVICE_REMOVE, service);
  },

  registerServiceAdd: functionToCall => eventEmitter.on(WS_SERVICE_ADD, functionToCall),
  unregisterServiceAdd: functionToCall =>
    eventEmitter.removeListener(WS_SERVICE_ADD, functionToCall),
  registerServiceRemove: functionToCall => eventEmitter.on(WS_SERVICE_REMOVE, functionToCall),
  unregisterServiceRemove: functionToCall =>
    eventEmitter.removeListener(WS_SERVICE_REMOVE, functionToCall),

  emit: (service, payload) => eventEmitter.emit(createWebsocketEventType(service), payload)
};

const SEC_LEVEL = Object.freeze({
  TENANT: "t",
  MANUFACTURER: "m"
});

export const WS_EVENT = Object.freeze({
  LIVEEVENT: { name: "liveEvent", securityLevel: SEC_LEVEL.TENANT },
  SMARTTRACKSTOCKEVENT: { name: "smartTrackStockEvent", securityLevel: SEC_LEVEL.TENANT },
  LIVEALERT: { name: "liveAlert", securityLevel: SEC_LEVEL.TENANT },
  MANUFACTURERMATCHINGEVENT: { name: "matchingEvent", securityLevel: SEC_LEVEL.MANUFACTURER }
});
