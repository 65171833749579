import { TopLoadingIndicator } from "common/TopLoadingIndicator";
import React, { lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const CloudApp = lazy(() => import(/* webpackChunkName: 'CloudApp' */ "app-cloud/base/CloudApp"));
const SSPApp = lazy(() => import(/* webpackChunkName: 'SSPApp' */ "app-selfservice/SSPApp"));
const ManuApp = lazy(() => import(/* webpackChunkName: 'ManuApp' */ "app-manufacturing/ManuApp"));

const AppWrapper = () => {
  return (
    <>
      <TopLoadingIndicator />
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/manufacturing/*" element={<ManuApp />} />
          <Route path="/selfService/*" element={<SSPApp />} />
          <Route path="/*" element={<CloudApp />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

const App = () => {
  return <AppWrapper />;
};

const root = createRoot(document.getElementById("app"));
root.render(<App />);
