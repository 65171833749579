export const colors = {
  background: "rgb(245, 248, 251)",
  primaryLight: "rgb(72, 159, 223)",
  primaryMain: "rgb(0, 97, 160)",
  secondaryMain: "rgb(241, 189, 12)",
  colorAdmin: "#f1bd0c",
  colorErrorText: "#a70000",
  colorError: "#ffb6b6",
  colorSuccessText: "#408000",
  colorSuccess: "#dcffbb",
  colorWarnText: "#7f5f01",
  colorWarn: "#f8e6a0"
};
